// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Image, Link, Stack } from '@chakra-ui/react';
import { appleStoreAppUrl } from '~/common/links';

export const MobileButtons = () => {
  return (
    <Stack direction="row">
      <Link href={appleStoreAppUrl}>
        <Image
          h="40px"
          src="/landing/app-store-badge@2x.png"
          alt="Mobile download"
        />
      </Link>
      <Link href="/android">
        <Image
          h="40px"
          src="/landing/play-store-badge@2x.png"
          alt="Mobile download"
        />
      </Link>
    </Stack>
  );
};
